// This file is part of Materials Cloud Archive
// Copyright (C) 2023 Materials Cloud Team.

/**
 * Overridden components of upload form.
 */
import { i18next } from "@translations/invenio_rdm_records/i18next";
import { connect } from "react-redux";
import React, { Fragment } from "react";
import {
  AccordionField,
  ArrayField,
  FieldLabel,
  GroupField,
  RichInputField,
  SelectField,
  TextField
} from "react-invenio-forms";
import {
  CreatibutorsField,
  DepositStatusBox,
  FormFeedback,
  PreviewButton,
  PublishButton,
  SaveButton,
  SubjectsField,
} from "@js/invenio_rdm_records";

import { Button, Card, Form, Grid, Icon } from "semantic-ui-react";
import _get from "lodash/get";
import currentDate from "./todayDate";

const defaultLabels = {
    "metadata.creators": "Authors",
    // "metadata.contributors": "Authors",
    "metadata.rights": "Licenses",
    "metadata.languages": "Languages",
    "metadata.dates": "Dates",
    "metadata.version": "Version",
    "metadata.publisher": "Publisher",
    "metadata.related_identifiers": "References - Related works",
    "metadata.references": "Materials Cloud section",
    "metadata.identifiers": "Alternate identifiers",
    "metadata.subjects": "Keywords",
    "access.embargo.until": "Embargo until",
    "pids.doi": "DOI",
};

// Form feedback
const mapStateToPropsFormFeedback = (state) => ({
  actionState: state.deposit.actionState,
  record: state.deposit.record,
  errors: state.deposit.errors,
});

const FormFeedbackComp = (props) => {
  const labels = {
    ...defaultLabels,
    ...props.labels,
  };
  if (props.actionState == 'DRAFT_PUBLISH_FAILED'
      && props.record.is_published == true
      && props.errors.message == 'Permission denied.'
  ) {
    props.errors.message = "Permission denied: record not published. For published records it is possible to only change keywords and references.";
  }
  // TODO add error message for restricted files and/or record,
  // at the moment it displays A validation error occurred.
  return (
    <FormFeedback
    fieldPath="message"
    labels={labels}
    />
  );
};

const StateFormFeedbackComp = connect(
  mapStateToPropsFormFeedback,
  null
)(FormFeedbackComp);


const FormFeedbackComponent = () => {
  return(<StateFormFeedbackComp />);
};


// DOI component
const DOIComponent = (props) => {
    if (typeof props.record.pids.doi !== 'undefined') {
      const doi = props.record.pids.doi.identifier;
      if (doi) {
        return (
          <Fragment>
          <b>
          <FieldLabel icon={"barcode"} label={"Digital Object Identifier"} />
          </b>
          <br/><br/>
          <label>{doi}</label>
          <br/>
          <label class="helptext">{"The DOI will resolve only after the record has been reviewed and published by the Materials Cloud Archive moderators."}</label>
          </Fragment>
        );
      } else {
        return (<></>);
      }
    } else {
      return (<></>);
    }
};

// Title component
const TitleComponent = (props) => {
    const { fieldPath, label, required } = props;
    return (
      <>
        <TextField
          fieldPath={fieldPath}
          label={<FieldLabel htmlFor={fieldPath} icon="book" label={label} />}
          required={required}
          className="title-field"
          optimized
        />
      </>
    );
};

// Description component
const DescriptionComponent = (props) => {
    const { fieldPath, label, labelIcon, editorConfig } = props;
    return (
      <>
        <RichInputField
          className="description-field rel-mb-1"
          fieldPath={fieldPath}
          editorConfig={editorConfig}
          label={<FieldLabel htmlFor={fieldPath} icon={labelIcon} label={label} />}
          optimized
          required
        />
      </>
    );
};

// Authors component
const CreatibutorsComponent = (props) => {
    props.modal.addLabel = "Add author";
    props.modal.editLabel = "Edit author";
    props.vocabularies.metadata.creators.type = [{ text: "Person", value: "personal" }]
    props.vocabularies.metadata.contributors.type = [{ text: "Person", value: "personal" }]
    return (
        <>
        <CreatibutorsField
          addButtonLabel={i18next.t("Add author")}
          label={i18next.t("Authors")}
          labelIcon="user"
          fieldPath="metadata.creators"
          roleOptions={props.config.vocabularies.creators.role}
          schema="creators"
          autocompleteNames={props.config.autocomplete_names}
          required
        />
        <label class="helptext" style={{ color: "red" }}>{"To add an author click on Add author and select Person, do not select Organisation."}</label>
        </>
    );
};


// RecommendedInformationComponent/Keywords component
const RecommendedInformationComponent = (props) => {
  return (
    <AccordionField
      includesPaths={[
        "metadata.subjects",
      ]}
      active
      label="Keywords"
    >
    <SubjectsField
      fieldPath="metadata.subjects"
      label="Keywords"
      placeholder="Search for a keyword by name"
      initialOptions={_get(props.record, "ui.subjects", null)}
      limitToOptions={props.vocabularies.metadata.subjects.limit_to}
    />
    <label htmlFor="metadata.subjects" className="helptext">
      <p style={{ color: "red" }}>Insert minimum 3 keywords.</p>
      If this work is funded by one of the Materials Cloud partners please add the keyword that identify the partner.
    </label>
    </AccordionField>
  );
};

// Related works - Link to older versions of record
const RelatedWorksFieldComponent = (props) => {
  const { fieldPath, label, labelIcon, required, options, showEmptyValue } = props;
  return (
    <>
      <label htmlFor={fieldPath}  className="helptext" style={{ marginBottom: "10px" }}>
        Specify identifiers of references and related works.<br/>
        Supported identifiers schemes are Bibcode, DOI, Handle, arXiv, ISBN, W3ID, URNs, and URLs.
      </label>
      <ArrayField
        addButtonLabel="Add related works"
        defaultNewValue=""
        fieldPath={fieldPath}
        label={<FieldLabel htmlFor={fieldPath} icon={labelIcon} label="" />}
        required={required}
        showEmptyValue={showEmptyValue}
      >
        {({ arrayHelpers, indexPath }) => {
          const fieldPathPrefix = `${fieldPath}.${indexPath}`;

          return (
            <>
              <GroupField optimized>
                <SelectField
                  clearable
                  fieldPath={`${fieldPathPrefix}.relation_type`}
                  label="Relation"
                  optimized
                  options={options.relations}
                  placeholder="Select relation..."
                  required
                  width={4}
                />

                <SelectField
                  clearable
                  fieldPath={`${fieldPathPrefix}.scheme`}
                  label="Scheme"
                  optimized
                  options={options.scheme}
                  required
                  width={4}
                />

                <TextField
                  fieldPath={`${fieldPathPrefix}.identifier`}
                  label="Identifier"
                  required
                  width={6}
                />

                <Form.Field>
                  <Button
                    aria-label="Remove field"
                    className="close-btn"
                    icon
                    onClick={() => arrayHelpers.remove(indexPath)}
                  >
                    <Icon name="close" />
                  </Button>
                </Form.Field>
              </GroupField>
            </>
          );
        }}
      </ArrayField>
      <label htmlFor={fieldPath}  className="helptext" style={{ color: "red" }}>
        For links to references select Relation: "Is Supplement to".<br/>
        For links to previous versions of this record select Relation: "Is new version of".<br/>
      </label>
    </>
  );
};

const AccordionFieldRelatedWorksComponent = (props) => {
    return(
        <AccordionField
        includesPaths={["metadata.related_identifiers"]}
        active
        label="References - Related works"
        >
            <RelatedWorksFieldComponent
            fieldPath="metadata.related_identifiers"
            options={props.vocabularies.metadata.identifiers}
            showEmptyValue
            />
        </AccordionField>
    );
};

// Side bar column
// Case draft is declined, do not display buttons Save/Preview/Publish
const mapStateToProps = (state) => ({
    depositEditorState: state.deposit.editorState,
    depositStatus: state.deposit.record.status,
    depositPermission: state.deposit.permissions,
    depositMetadata: state.deposit.record.metadata,
});

const CardDepositStatusBoxComp = (props) => {
    const status = props.depositStatus;
    if (!status) {
        throw new Error("Status is undefined");
    }
    props.depositEditorState.ui.showCommunitySelectionButton = false;
    props.depositEditorState.ui.disableCommunitySelectionButton = true;
    props.depositEditorState.ui.showChangeCommunityButton = false;
    props.depositEditorState.ui.showCommunityHeader = false;

    if (status != "published") {
      props.depositEditorState.ui.showSubmitForReviewButton = true;
    }

    // show 'Submit for review' button if new version draft
    if (status == "new_version_draft") {
      // Needed to allow review of new versions
      props.depositEditorState.actions.communityStateMustBeChecked = true;
      props.depositEditorState.actions.shouldDeleteReview = false;
      props.depositEditorState.actions.shouldUpdateReview = true;

      // Publication date is missing in new version: need to add it
      props.depositMetadata.publication_date = currentDate.currentDate;
    }

    if (status !== "declined") {
        return (
            <Card>
            <Card.Content>
                <DepositStatusBox />
            </Card.Content>
            <Card.Content>
                <Grid relaxed>
                    <Grid.Column
                    computer={8}
                    mobile={16}
                    className="pb-0 left-btn-col"
                    >
                    <SaveButton fluid />
                    </Grid.Column>

                    <Grid.Column
                    computer={8}
                    mobile={16}
                    className="pb-0 right-btn-col"
                    >
                    <PreviewButton fluid />
                    </Grid.Column>

                    <Grid.Column width={16} className="pt-10">
                    <PublishButton fluid />
                    </Grid.Column>
                </Grid>
            </Card.Content>
            </Card>
        );
    }
    return(
        <Card>
        <Card.Content>
            <DepositStatusBox />
        </Card.Content>
        </Card>
    );
};

const StateToCardDepositStatusBoxComp = connect(
    mapStateToProps,
    null
  )(CardDepositStatusBoxComp);


const CardDepositStatusBoxComponent = () => {
    return(<StateToCardDepositStatusBoxComp />);
};

export {
    FormFeedbackComponent,
    DOIComponent,
    TitleComponent,
    DescriptionComponent,
    CreatibutorsComponent,
    RecommendedInformationComponent,
    CardDepositStatusBoxComponent,
    RelatedWorksFieldComponent,
    AccordionFieldRelatedWorksComponent,
};