// This file is part of Materials Cloud Archive
// Copyright (C) 2023 Materials Cloud Team.

/**
 * Get current date
 */
const date = new Date();
let currentDay= String(date.getDate()).padStart(2, '0');
let currentMonth = String(date.getMonth()+1).padStart(2,'0');
let currentYear = date.getFullYear();
let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
export default { currentDate };