// This file is part of Materials Cloud Archive
// Copyright (C) 2023 Materials Cloud Team.

/**
 * Overridden components.
 */

import React from "react";
import {
    FormFeedbackComponent,
    DOIComponent,
    TitleComponent,
    DescriptionComponent,
    CreatibutorsComponent,
    RecommendedInformationComponent,
    CardDepositStatusBoxComponent,
    // AccordionFieldAlternateIdentifiersComponent,
    // IdentifiersFieldComponent,
    AccordionFieldRelatedWorksComponent,
    RelatedWorksFieldComponent,
    // AccordionFieldReferencesComponent,
    // ReferencesFieldComponent,
 } from "../uploadForm.js";
import { MCARecordsResultsListItem } from "../MCARecordsResultsListItem.js";
import { MCARDMRecordResultsListItem } from "../user_dashboard/uploads.js";


const EmptyComponent = () => {
    return (<></>);
};

export const overriddenComponents = {
    "ReactInvenioDeposit.FileUploaderToolbar.MetadataOnlyToggle.container": EmptyComponent,
    "InvenioAppRdm.Deposit.FormFeedback.container": FormFeedbackComponent,
    "InvenioAppRdm.Deposit.CommunityHeader.container": EmptyComponent,
    "InvenioAppRdm.Deposit.PIDField.container": DOIComponent,
    "InvenioAppRdm.Deposit.TitlesField.container": TitleComponent,
    "InvenioAppRdm.Deposit.DescriptionsField.container": DescriptionComponent,
    "InvenioAppRdm.Deposit.CreatorsField.container": CreatibutorsComponent,
    "InvenioAppRdm.Deposit.AccordionFieldFunding.container": EmptyComponent,
    "InvenioAppRdm.Deposit.AccordionFieldRecommendedInformation.container": RecommendedInformationComponent,
    "InvenioAppRdm.Deposit.CardDepositStatusBox.container": CardDepositStatusBoxComponent,
    "InvenioAppRdm.Deposit.AccessRightField.container": EmptyComponent,
    "InvenioAppRdm.Deposit.AccordionFieldAlternateIdentifiers.container": EmptyComponent,
    // "InvenioAppRdm.Deposit.AccordionFieldAlternateIdentifiers.container": AccordionFieldAlternateIdentifiersComponent,
    // "InvenioAppRdm.Deposit.IdentifiersField.container": IdentifiersFieldComponent,
    "InvenioAppRdm.Deposit.AccordionFieldRelatedWorks.container": AccordionFieldRelatedWorksComponent,
    "InvenioAppRdm.Deposit.RelatedWorksField.container": RelatedWorksFieldComponent,
    "InvenioAppRdm.Deposit.AccordionFieldReferences.container": EmptyComponent,
    // "InvenioAppRdm.Deposit.ReferencesField.container": ReferencesFieldComponent,
    // "InvenioAppRdm.Deposit.ResourceTypeField.container": ResourceTypeFieldComponent,
    "InvenioAppRdm.Deposit.PublicationDateField.container": EmptyComponent,

    // overrides the item list in frontpage to remove DisplayPartOfCommunities and change the record link to record/<mcid>
    "InvenioAppRDM.RecordsList.RecordsResultsListItem.layout": MCARecordsResultsListItem,

    // overrides the item list in search to remove DisplayPartOfCommunities and change the record link to record/<mcid>
    "InvenioAppRdm.Search.RecordsResultsListItem.layout": MCARecordsResultsListItem,

    // overrides the item list in My records to remove DisplayPartOfCommunities
    "InvenioAppRdm.DashboardUploads.ResultsList.item": MCARDMRecordResultsListItem,
}
